/* 通用方法 */
export const getDictText = function(dict, value) {
    var cars = dict.filter(item => { return item.value == value });
    if (cars.length > 0) {
        return cars[0].text;
    }
    return "";
};
/* 工作计划 - 类型 */
export const visitPlanCategory = [{ text: '外勤', value: 0 }, { text: '公司', value: 1 }];
/* 工作计划 - 等级 */
export const visitPlanPriority = [{ text: '重要', value: 0 }, { text: '常规', value: 1 }];
/* 工作计划 - 状态 */
export const visitPlanState = [{ text: '待处理', value: 0 }, { text: '已到达', value: 1 }, { text: '已完成', value: 2 }, { text: '已取消', value: 3 }];