<template>
  <div class="mobile-list">
    <searchBox placeholder="请输入关键字" :onTextChanged="textChanged" :onCancel="cancel" :onSearchClicked="searchClicked" :msg="enterkey"></searchBox>
    <van-dropdown-menu active-color="#1989fa">
      <van-dropdown-item v-model="filterOption.type" :options="optionType" @change="firstPage()" />
      <van-dropdown-item v-if="filterOption.type==1" v-model="filterOption.state" :options="optionState" @change="firstPage()" />
      <van-dropdown-item v-if="filterOption.type==1" v-model="filterOption.orderBy" :options="optionOrderBy" @change="firstPage()" />
    </van-dropdown-menu>
    <div class="mobile-list-content">
      <orderScroll ref="scroll" :data="items" :options="options" :loading="loading" :firstPage="firstPage" :nextPage="nextPage">
        <div class="mobile-list-card" v-for="(item, index) in items" :key="index">
          <div class="mobile-list-row" v-if="filterOption.state==9">
            <div class="label">计划日期：</div>
            <div class="value">{{item.time}} </div>
          </div>
          <div class="mobile-list-row" v-if="filterOption.state==9">
            <div class="label">未提交人：</div>
            <div class="value">{{item.user_name}} </div>
          </div>
          <div class="mobile-list-row" v-if="filterOption.state!=9 && filterOption.type==2">
            <div class="label">总结人员：</div>
            <div class="value">{{item.model.user_name}} </div>
          </div>
          <div class="mobile-list-row" v-if="filterOption.state!=9 && filterOption.type==2">
            <div class="label">总结时间：</div>
            <div class="value">{{formatDate(item.model.time,'yyyy-MM-dd hh:mm')}} </div>
          </div>
          <div class="mobile-list-row" v-if="filterOption.state!=9 && filterOption.type==2">
            <div class="label">总结内容：</div>
            <div class="value">{{item.model.memo}} </div>
          </div>
          <div class="mobile-list-row" v-if="filterOption.state!=9 && filterOption.type==1">
            <div class="label">计划人员</div>
            <div class="value">{{item.model.user_name}} </div>
          </div>
          <div class="mobile-list-row" v-if="filterOption.state!=9 && filterOption.type==1">
            <div class="label">计划日期：</div>
            <div class="value">{{formatDate(item.model.time)}} </div>
          </div>
          <div class="mobile-list-row" v-if="item.model && item.model.project_name">
            <div class="label">相关项目：</div>
            <div class="value">{{item.model.project_name}} </div>
          </div>
          <div class="mobile-list-row" v-if="item.model && item.model.customer_name">
            <div class="label">拜访客户：</div>
            <div class="value">{{item.model.customer_name}} </div>
          </div>
          <div class="mobile-list-row" v-if="item.model && item.model.cause">
            <div class="label">计划内容：</div>
            <div class="value">{{item.model.cause}} </div>
          </div>
          <div class="mobile-list-row" v-if="filterOption.state!=9 && filterOption.type==1">
            <div class="label">计划状态：</div>
            <div class="value" :class="getStatusColor(item.model.state)">{{getDictText(itemState,item.model.state)}} </div>
          </div>
          <div class="mobile-list-row" v-if="item.model && item.model.fact_time">
            <div class="label">到达时间：</div>
            <div class="value">{{formatDate(item.model.fact_time,'yyyy-MM-dd hh:mm')}} </div>
          </div>
          <div class="mobile-list-row" v-if="item.model && item.model.fact_address">
            <div class="label">到达位置：</div>
            <div class="value">{{item.model.fact_address}} </div>
          </div>
          <div class="mobile-list-row" v-if="item.model && item.model.finish_time">
            <div class="label">完成时间：</div>
            <div class="value">{{formatDate(item.model.finish_time,'yyyy-MM-dd hh:mm')}}</div>
          </div>
          <div class="mobile-list-row" v-if="item.model && item.model.finish_memo">
            <div class="label">完成备注：</div>
            <div class="value">{{item.model.finish_memo}}</div>
          </div>
          <comment :id="item.model.id" :data="item.items" order_name="工作计划" v-if="filterOption.state!=9"></comment>
        </div>
      </orderScroll>
    </div>
  </div>
</template>

<script>
import { GetVisitPlanItems, GetVisitPlanReviews } from "@/api";
import { formatDate } from "@/utils/format.js";
import { getDictText, visitPlanState } from "@/utils/dict.js";
import { loadListAction } from "@/utils/action.js";

export default {
  data: function () {
    return {
      queryAction: "",
      items: [],
      options: {
        pullDownRefresh: {
          threshold: 60,
          stopTime: 500,
          txt: "刷新成功",
        },
        pullUpLoad: true,
      },
      pageIndex: 1,
      pageSize: 10,
      total: 0,
      loading: false,
      hasMore: true,
      showingFilter: false,
      filterOption: {
        startTime: null,
        endTime: null,
        state: 2,
        type: 1,
        orderBy: 1,
        dept: 0,
      },
      enterkey: [],
      keyword: "",
      itemState: visitPlanState,
      optionType: [
        { text: '日报', value: 1 },
        { text: '总结', value: 2 },
      ],
      optionState: [
        { text: '已完成', value: 2 },
        { text: '已到达', value: 1 },
        { text: '已取消', value: 3 },
        { text: '待处理', value: 0 },
        { text: '未提交', value: 9 },
      ],
      optionOrderBy: [
        { text: '计划日期倒序', value: 0 },
        { text: '默认排序', value: 1 },
      ]
    };
  },
  methods: {
    formatDate: formatDate,
    getDictText: getDictText,
    getStatusColor: function (state) {
      switch (state) {
        case 1:
          return "link-font-color";
        case 2:
          return "green-font-color";
        case 3:
          return "grey-font-color";
        default:
          return "orange-font-color";
      }
    },
    cancel: function () {
      this.enterkey.length = 0;
    },
    add: function () {
      this.$router.push({
        name: "mVacationDetail",
        query: {
          id: "add",
        }
      });
    },
    itemClick: function (item) {
      this.$router.push({
        name: "mVacationDetail",
        query: {
          id: item.id,
        },
      });
    },
    textChanged: function (keywords) {
      this.keyword = keywords;
    },
    searchClicked: function (keywords) {
      this.keyword = keywords;
      this.firstPage();
    },
    firstPage: function () {
      this.pageIndex = 1;
      this.items = [];
      this.dataRequest();
    },
    nextPage: function () {
      if (this.loading || !this.hasMore) {
        this.$refs.scroll.forceUpdate(true);
      } else {
        this.dataRequest();
      }
    },
    dataRequest: function () {
      loadListAction(this, this.filterOption.type == 2 ? GetVisitPlanReviews : GetVisitPlanItems, {
        sTime: this.filterOption.startTime,
        eTime: this.filterOption.endTime,
        state: this.filterOption.state,
        type: this.filterOption.type,
        dept: this.filterOption.dept,
        keyword: this.keyword,
        action: this.queryAction,
        page: {
          pageIndex: this.pageIndex,
          pageSize: this.pageSize,
          orderBy: this.filterOption.orderBy,
        }
      }, (data) => {
        this.total = data.totalCount;
        if (this.pageIndex == 1) {
          this.items = [];
        }
        if (data.list.length > 0) {
          this.items = this.items.concat(data.list);
          this.pageIndex += 1;
        }
        this.hasMore = this.total > this.items.length;
        this.$refs.scroll.forceUpdate(true);
      });
    }
  },
  mounted: function () {
    this.queryAction = this.$route.query["action"];
    this.firstPage();
  },
};
</script>
<style>
.tabPanel {
  height: calc(100% - 50px);
  width: 100%;
  background-color: #f9f9f9;
}
.van-sticky {
  margin-top: 46px;
}
.van-tabs {
  margin-bottom: 50px;
}
.van-loading {
  margin-top: 100px;
}
.mobile-list-row {
  margin-top: 10px !important;
}
.mobile-list-row .label {
  width: 78px;
  flex: 0 0 78px;
  color: #999;
  line-height: 20px;
}
.mobile-list-row .value {
  display: flex;
  flex: 1;
  min-width: 0;
  white-space: pre-wrap;
  line-height: 20px;
}
</style>